import * as React from "react";
import "./index.css"


function App() {
  return (
      <div class="content">
        <div class="gavi">GAVI</div>
        <div class="divider"></div>
        <h2>Investments & Holdings</h2>
        <p class="description">We invest in early stage tech startups.</p>
        <p class="contact">
            Write to <a href="mailto:vivek@gavi.co.in">us</a> for more information.
        </p>
    </div>
  );
}

export default App;
